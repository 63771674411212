<template>
  <b-modal
      id="calendar-activity-modal-id"
      hide-header
      hide-footer
      hide-header-close
      size="lg"
      header-class="my-second-class"
      :body-bg-variant="'gray-lighten'"
  >
    <b-row>
      <b-col align="center" class="modal-title-class">
        {{ timetable.id ? $t('components.custom.playground.new.step-2.calendar.update')  : $t('components.custom.playground.new.step-2.calendar.title') }}
      </b-col>
    </b-row>
    <div class="border-bottom-grey-dark mt-3 mb-3">
    </div>
    <new-calendar-form
        :timetable="timetable"
    />
    <b-row
        class="mt-3"
    >
      <b-col cols="1"></b-col>
      <b-col class="pr-0" align="right">
        <d-button
            :text="'general.actions.save'"
            :class="'d-btn-sm font-text-title d-btn btn d-btn-danger font-text-title'"
            @on:button-click="createOrUpdateTimetable"
        />
      </b-col>
      <b-col cols="1"></b-col>
    </b-row>
  </b-modal>
</template>
<script>
import Timetable from "@/classes/doinsport/Timetable";
import {fromIdToIriReference} from "@/utils/form";
import {postTimetable, putTimetable} from "@api/doinsport/services/timetable/timetable.api";

export default {
  components: {
    NewCalendarForm: () => import('@form/playground/new/step-2/NewCalendarForm')
  },
  data: () => ({
    btnSwitch: true,
  }),
  props: {
    display: {
      type: Boolean,
      default: false,
    },
    timetable: {
      type: Object,
      default: this,
    },
  },
  watch: {
    display: function (changes) {
      this.$bvModal.show('calendar-activity-modal-id');
    }
  },
  methods: {
    getTimetableRequest() {
      const serializedTimetable = new Timetable(this.timetable, {serialize: true});
      if (this.timetable.id === null) {
        serializedTimetable.playgrounds.push(fromIdToIriReference('/clubs/playgrounds', this.$route.params.id));
      }

      return this.timetable.id ? putTimetable(serializedTimetable) : postTimetable(serializedTimetable);
    },
    createOrUpdateTimetable() {
      this.$bvModal.hide('calendar-activity-modal-id');

      this.getTimetableRequest()
          .then((response) => {
            this.$store.dispatch('playgrounds/updateTimetable', response.data);
            this.$emit('refresh-calendars', response.data);
          })
      ;
    }
  }
}
</script>
<style scoped>
.modal-title-class {
  text-align: center;
  font: normal normal 500 25px Avenir;
  letter-spacing: 0px;
  color: #3C3D43;
  opacity: 1;
}

/deep/ .modal {
  padding-top: 88px
}

/deep/ .modal-content {
  border-radius: 8px;
}

/deep/ .modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding: 1rem;
  border-radius: 8px;
}

</style>
